<template>
  <div class="ma-4">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Key Request Form</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>Please complete this form to request keys. Requests can be made for more than one person at a time, and for more than one key at a time.</p>
        <p>If you have questions or problems, please contact the Safety & Security office at safety@covenant.edu or by phone at 706.419.1159.</p>
      </v-card-text>
    </v-card>
    <v-card class="ma-4">
      <v-card-title>Key Recipients</v-card-title>
      <v-card-text class="mb-0 pb-0">
        <p>Please search for and select the recipient(s) to whom we should be checking out the keys.</p>
        <directory-search ref="directorySearch" v-model="recipient" value-field="email" search-label="Recipient Search" include-photo hide-details></directory-search>
      </v-card-text>
      <v-list class="pt-0">
        <v-subheader>Selected Recipients</v-subheader>
        <v-list-item v-for="({ avatar, text, subtext, value }, index) in recipientList" :key="value">
          <v-list-item-avatar>
            <v-img :src="avatar || '/img/no.jpg'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
            <v-list-item-subtitle>{{ subtext }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="recipientList.splice(index, 1)">
                  <v-icon color="error">fal fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Remove user from list</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card-text v-if="recipientList.length === 0" class="pt-0">
        <v-alert type="info" outlined class="mb-0">No Recipients Selected</v-alert>
      </v-card-text>
    </v-card>
    <v-card class="ma-4">
      <v-card-title>Keys</v-card-title>
      <v-card-text class="pb-0">
        <p>Please add the keys that you are requesting on behalf of the above recipients. You can search by location with the box below, or you can enter any text that you wish then press enter to add that text to the list.</p>
        <v-combobox v-model="key" :items="keyOptions" label="Add Key to List (Location, Code, or Description)" outlined hide-details></v-combobox>
      </v-card-text>
      <v-list class="pt-0 pb-0">
        <v-subheader>Keys Selected</v-subheader>
        <v-list-item v-for="(key, index) in keysSelected" :key="key">
          <v-list-item-title>{{ key }}</v-list-item-title>
          <v-list-item-action>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="keysSelected.splice(index, 1)">
                  <v-icon color="error">fal fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Remove Key</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card-text v-if="keysSelected.length === 0" class="pt-0">
        <v-alert type="info" outlined class="mb-0">No Keys Selected</v-alert>
      </v-card-text>
    </v-card>
    <v-card class="ma-4">
      <v-card-title>Final Details</v-card-title>
      <v-card-text>
        <directory-search v-if="approver == null || approver.value == null || approver.value === ''" v-model="approver" value-field="email" search-label="Approver Search" :person-filter="['Faculty', 'Staff']" include-photo></directory-search>
        <v-list v-else>
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="approver.avatar || '/img/no.jpg'"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ approver.text }}</v-list-item-title>
              <v-list-item-subtitle>{{ approver.subtext }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="approver = null">
                    <v-icon color="error">fal fa-times-circle</v-icon>
                  </v-btn>
                </template>
                <span>Choose Different Approver</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-row>
          <v-col>
            <v-select v-model="keyReturn" :items="returnOptions" label="Key Needed Until" outlined></v-select>
          </v-col>
          <v-col v-if="keyReturn === 'custom'">
            <date-picker v-model="customDate" :min-date="customDateMin" outlined></date-picker>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
  components: {
    DirectorySearch: () => import('@/components/greatscots/searchField'),
    DatePicker: () => import('@/components/forms/inputs/DatePicker')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const directorySearch = ref(null)
    const recipient = ref(null)
    const recipientList = ref([])

    watch(recipient, (val) => {
      if (val != null && 'value' in val && val.value != null && val.value !== '') {
        recipientList.value.push(val)
        directorySearch.value.clear()
      }
    })

    const key = ref(null)
    const keyOptions = ref([])
    onMounted(() => {
      root.$feathers.service('forms/select-options').get('64d2956944dea9c054917bf8').then((data) => {
        keyOptions.value = data.options
      })
      root.$feathers.service('directory/people').get(user.value.directoryId).then((data) => {
        recipientList.value.push({
          text: data.name.first + ' ' + data.name.last,
          subtext: data.person,
          avatar: data.photo,
          value: data.email
        })
      })
    })
    const keysSelected = ref([])
    watch(key, (val) => {
      if (val == null) return
      if (typeof (val) === 'object') {
        if ('value' in val) {
          if (keysSelected.value.filter((l) => l === val.value).length === 0) {
            keysSelected.value.push(val.value)
          }
        }
      } else {
        if (keysSelected.value.filter((l) => l === val).length === 0) {
          keysSelected.value.push(val)
        }
      }
      root.$nextTick(() => { key.value = null })
    })

    const approver = ref(null)
    const keyReturn = ref('')
    const returnOptions = ref([
      { text: 'End of Semester', value: 'End of Semester' },
      { text: 'End of School Year', value: 'End of School Year' },
      { text: 'End of Employment', value: 'End of Employment' },
      { text: 'Custom Date', value: 'custom' }
    ])
    const customDate = ref('')
    const customDateMin = computed(() => {
      const dt = new Date()
      dt.setDate(dt.getDate() + 1)
      return dt.toISOString().substring(0, 10)
    })

    return {
      user,
      directorySearch,
      recipient,
      recipientList,
      key,
      keyOptions,
      keysSelected,
      approver,
      keyReturn,
      returnOptions,
      customDate,
      customDateMin
    }
  }
}
</script>
