var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4"},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Key Request Form")])],1),_c('v-card-text',[_c('p',[_vm._v("Please complete this form to request keys. Requests can be made for more than one person at a time, and for more than one key at a time.")]),_c('p',[_vm._v("If you have questions or problems, please contact the Safety & Security office at safety@covenant.edu or by phone at 706.419.1159.")])])],1),_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Key Recipients")]),_c('v-card-text',{staticClass:"mb-0 pb-0"},[_c('p',[_vm._v("Please search for and select the recipient(s) to whom we should be checking out the keys.")]),_c('directory-search',{ref:"directorySearch",attrs:{"value-field":"email","search-label":"Recipient Search","include-photo":"","hide-details":""},model:{value:(_vm.recipient),callback:function ($$v) {_vm.recipient=$$v},expression:"recipient"}})],1),_c('v-list',{staticClass:"pt-0"},[_c('v-subheader',[_vm._v("Selected Recipients")]),_vm._l((_vm.recipientList),function(ref,index){
var avatar = ref.avatar;
var text = ref.text;
var subtext = ref.subtext;
var value = ref.value;
return _c('v-list-item',{key:value},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":avatar || '/img/no.jpg'}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(subtext))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.recipientList.splice(index, 1)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove user from list")])])],1)],1)})],2),(_vm.recipientList.length === 0)?_c('v-card-text',{staticClass:"pt-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info","outlined":""}},[_vm._v("No Recipients Selected")])],1):_vm._e()],1),_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Keys")]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v("Please add the keys that you are requesting on behalf of the above recipients. You can search by location with the box below, or you can enter any text that you wish then press enter to add that text to the list.")]),_c('v-combobox',{attrs:{"items":_vm.keyOptions,"label":"Add Key to List (Location, Code, or Description)","outlined":"","hide-details":""},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1),_c('v-list',{staticClass:"pt-0 pb-0"},[_c('v-subheader',[_vm._v("Keys Selected")]),_vm._l((_vm.keysSelected),function(key,index){return _c('v-list-item',{key:key},[_c('v-list-item-title',[_vm._v(_vm._s(key))]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.keysSelected.splice(index, 1)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove Key")])])],1)],1)})],2),(_vm.keysSelected.length === 0)?_c('v-card-text',{staticClass:"pt-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info","outlined":""}},[_vm._v("No Keys Selected")])],1):_vm._e()],1),_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',[_vm._v("Final Details")]),_c('v-card-text',[(_vm.approver == null || _vm.approver.value == null || _vm.approver.value === '')?_c('directory-search',{attrs:{"value-field":"email","search-label":"Approver Search","person-filter":['Faculty', 'Staff'],"include-photo":""},model:{value:(_vm.approver),callback:function ($$v) {_vm.approver=$$v},expression:"approver"}}):_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.approver.avatar || '/img/no.jpg'}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.approver.text))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.approver.subtext))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.approver = null}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")])],1)]}}])},[_c('span',[_vm._v("Choose Different Approver")])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.returnOptions,"label":"Key Needed Until","outlined":""},model:{value:(_vm.keyReturn),callback:function ($$v) {_vm.keyReturn=$$v},expression:"keyReturn"}})],1),(_vm.keyReturn === 'custom')?_c('v-col',[_c('date-picker',{attrs:{"min-date":_vm.customDateMin,"outlined":""},model:{value:(_vm.customDate),callback:function ($$v) {_vm.customDate=$$v},expression:"customDate"}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }